.dropdownContainer{
    @apply z-50 fixed select-none bg-white flex flex-col inset-0 h-screen w-screen top-16;
}

@screen sm {
    .dropdownContainer{
        @apply absolute inset-auto h-auto w-auto right-0 shadow rounded-lg;
        top: calc(100% + 16px);
        max-height: 70vh;
    }

    .dropdownContainer::before {
        @apply absolute -top-2 right-3.5;
        @apply border-solid border-b-8 border-r-8 border-l-8;
        @apply border-transparent border-t-white border-b-white;
        filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.25));
        content: "";
    }
}
